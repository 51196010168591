import { DisasterRecoveryReplicationStrategySourceSite, Resource } from '@pure1/data';
import { DraasApiServiceLevelPolicy } from '@pure/paas-api-gateway-client-ts';
import moment, { Duration } from 'moment';

export class DisasterRecoveryPolicyV2 implements Resource {
    id: string;
    name: string;
    description: string;
    rpo: Duration;
    replicationStrategy: DisasterRecoveryReplicationStrategySourceSite;
    groupCount: number;
    version: number;

    constructor(json: DraasApiServiceLevelPolicy) {
        this.id = json.id;
        this.name = json.name;
        this.description = json.description;
        this.rpo = moment.duration(json.rpo);
        this.replicationStrategy = json.replication_strategy
            ? new DisasterRecoveryReplicationStrategySourceSite(json.replication_strategy)
            : null;
        this.groupCount = json.group_count;
        this.version = json.version;
    }
}
