import {
    DraasApiFailoverReport,
    DraasApiReportExecutionInfo,
    DraasApiReportProtectionGroup,
    DraasApiReportVirtualMachine,
} from '@pure/paas-api-gateway-client-ts';
import moment, { Duration, Moment } from 'moment';
import { DisasterRecoveryJobExecutionStatus } from '@pure1/data';

export type FailoverStepType =
    | 'AGGREGATION'
    | 'INGESTION'
    | 'DATA_LOAD'
    | 'AMI_CONVERSION'
    | 'PRE_SCRIPT'
    | 'IMAGE_BOOT'
    | 'WAITING'
    | 'CLEANUP'
    | 'QUEUED'
    | 'UNKNOWN';

export interface RtoSummaryProtectionGroup {
    id: string;
    name: string;
    size: number;
    vmdk: number;
    bootOrder: number;
    hasParallelBoot: boolean;
    executionInfo: RtoSummaryExecutionInfo;
    policyId: string;
    policyName: string;
    estimatedRto: Duration;
    vms: RtoSummaryVirtualMachine[];
}

export interface RtoSummaryVirtualMachine {
    id: string;
    name: string;
    size: number;
    vmdk: number;
    bootOrder: number;
    executionInfo: RtoSummaryExecutionInfo;
    steps: RtoSummaryExecutionInfo[];
}

export interface RtoSummaryExecutionInfo {
    start: Moment;
    end: Moment;
    status: DisasterRecoveryJobExecutionStatus;
    type: FailoverStepType;
}

export class DisasterRecoveryRtoSummaryReport {
    planName: string;
    executionInfo: RtoSummaryExecutionInfo;
    protectionGroups: RtoSummaryProtectionGroup[];

    constructor(json: DraasApiFailoverReport) {
        this.planName = json.recovery_plan?.name;
        this.executionInfo = this.convertExecutionInfo(json.aggregated_execution_info);
        this.protectionGroups = json.protection_groups.map(group => this.convertProtectionGroup(group));
    }

    private convertProtectionGroup(json: DraasApiReportProtectionGroup): RtoSummaryProtectionGroup {
        let totalDiskCount = 0;
        let totalSize = 0;
        const vms: RtoSummaryVirtualMachine[] = [];
        json.vms.forEach(vm => {
            vms.push(this.convertVirtualMachine(vm));
            totalDiskCount += vm.virtual_machine.disk_count ?? 0;
            totalSize += vm.virtual_machine.disk_total_size ?? 0;
        });

        return {
            id: json.protection_group.id,
            name: json.protection_group.name,
            size: totalSize,
            vmdk: totalDiskCount,
            bootOrder: json.order_in_plan,
            hasParallelBoot: json.protection_group.has_parallel_boot,
            executionInfo: this.convertExecutionInfo(json.aggregated_execution_info),
            policyId: json.policy.id,
            policyName: json.policy.name,
            estimatedRto: moment.duration(json.policy.replication_strategy.replication_targets[0].estimated_rto),
            vms,
        };
    }

    private convertVirtualMachine(json: DraasApiReportVirtualMachine): RtoSummaryVirtualMachine {
        return {
            id: json.virtual_machine.id,
            name: json.virtual_machine.name,
            size: json.virtual_machine.disk_total_size ?? null,
            vmdk: json.virtual_machine.disk_count ?? null,
            bootOrder: json.order_in_group,
            executionInfo: this.convertExecutionInfo(json.aggregated_execution_info),
            steps: json.executions.map(execution => this.convertExecutionInfo(execution)),
        };
    }

    private convertExecutionInfo(json: DraasApiReportExecutionInfo): RtoSummaryExecutionInfo {
        return {
            start: json.started ? moment.utc(json.started) : null,
            end: json.ended ? moment.utc(json.ended) : null,
            status: DisasterRecoveryJobExecutionStatus[json.status] || DisasterRecoveryJobExecutionStatus.UNKNOWN,
            type: json.execution_type,
        };
    }
}
